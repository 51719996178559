import React from 'react'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'
import {withNamespaces} from 'react-i18next'

import Layout from 'components/Layout'
import ProductsPanel from 'components/ProductsPanel'

import fleerpImg from './fleerp.png'

export class ProductsPage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)

    this.Fleerp = React.createRef()
  }

  handleClick = name => {
    this[name].current.scrollIntoView({behavior: 'smooth'})
  }

  render () {
    const {t, navigate, location} = this.props

    const products = [
      {
        ref: this.Fleerp,
        name: 'Fleerp',
        image: fleerpImg,
        description: t('ProductsPage.fleerp.description'),
        points: [
          t('ProductsPage.fleerp.points.gpsTrackingSystem'),
          t('ProductsPage.fleerp.points.suitableForBusiness'),
          t('ProductsPage.fleerp.points.simpleToUse'),
          t('ProductsPage.fleerp.points.support')
        ]
      }
    ]

    return (
      <Layout center={false} navigate={navigate} location={location}>
        <Helmet
          title={t('ProductsPage.meta.title')}
          meta={[
            {
              name: 'description',
              content: t('ProductsPage.meta.description')
            },
            {
              name: 'keywords',
              content: t('ProductsPage.meta.keywords')
            }
          ]}
        />
        <ProductsPanel products={products} onClick={this.handleClick} />
      </Layout>
    )
  }
}

export default withNamespaces()(ProductsPage)
