import React from 'react'
import PropTypes from 'prop-types'

import {Col, Grid, Row} from 'react-flexbox-grid'

const DescriptionPanel = ({products}) => (
  <Grid style={{marginTop: 30}}>
    {
      products.map((product, i) => (
        <Description key={i} even={i % 2 === 0} product={product} />
      ))
    }
  </Grid>
)

DescriptionPanel.propTypes = {
  products: PropTypes.array.isRequired
}

const Description = ({even, product}) => {
  if (even) {
    return (
      <div ref={product.ref} style={{borderBottom: '1px solid #dbdbdb'}}>
        <Row start='xs' middle='xs'>
          <Col md={3}>
            <img src={product.image} width='400px' />
          </Col>

          <Col md={6} mdOffset={2}>
            <h1>{product.name}</h1>
            <p>{product.description}</p>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div ref={product.ref} style={{borderBottom: '1px solid #dbdbdb'}}>
      <Row start='xs' middle='xs'>
        <Col md={6}>
          <h1>{product.name}</h1>
          <p>{product.description}</p>
        </Col>

        <Col md={3} mdOffset={2}>
          <img src={product.image} width='400px' />
        </Col>
      </Row>
    </div>
  )
}

Description.propTypes = {
  even: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired
}

export default DescriptionPanel
