import React from 'react'
import PropTypes from 'prop-types'

import SummaryPanel from './SummaryPanel'
import DescriptionPanel from './DescriptionPanel'

const ProductsPanel = ({products, onClick}) => (
  <div>
    <SummaryPanel products={products} onClick={onClick} />
    <DescriptionPanel products={products} />
  </div>
)

ProductsPanel.propTypes = {
  products: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
}

export default ProductsPanel
