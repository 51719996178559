import React from 'react'
import PropTypes from 'prop-types'

import {withNamespaces} from 'react-i18next'
import {Col, Grid, Row} from 'react-flexbox-grid'

import {Medium} from 'components/common/Responsive'

import classes from './ProductsPanel.module.scss'

const SummaryPanel = ({t, products, onClick}) => (
  <div className={classes.summary}>
    <Medium>
      {matches => (
        <Grid>
          <Row center='xs' start='sm' middle='xs'>
            <Col md={6} className={matches ? classes.company : classes.smallCompany}>
              <h1 className={matches ? classes.companyName : classes.smallCompanyName}>
                Clouway
              </h1>
              <p className={classes.companyDescription}>
                {t('ProductsPanel.SummaryPanel.details')}
              </p>
            </Col>
          </Row>

          <Row center='xs' start='md' middle='xs' top='md'>
            {
              products.map((product, i) => (
                <Col key={i} md={4}>
                  <Product product={product} onClick={onClick} />
                </Col>
              ))
            }
          </Row>
        </Grid>
      )}
    </Medium>
  </div>
)

SummaryPanel.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired
}

export class Product extends React.Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  }

  handleClick = () => {
    const {product, onClick} = this.props

    onClick(product.name)
  }

  render () {
    const {product} = this.props

    return (
      <div onClick={this.handleClick} className={classes.productSummary}>
        <h1 className={classes.name}>{product.name}</h1>
        <ul className={classes.features}>
          {
            product.points.map((p, i) => (
              <li className={classes.feature} key={i}>{p}</li>
            ))
          }
        </ul>
      </div>
    )
  }
}

export default withNamespaces()(SummaryPanel)
